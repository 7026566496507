exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-cloud-cloud-server-js": () => import("./../../../src/pages/cloud/cloud-server.js" /* webpackChunkName: "component---src-pages-cloud-cloud-server-js" */),
  "component---src-pages-cloud-js": () => import("./../../../src/pages/cloud.js" /* webpackChunkName: "component---src-pages-cloud-js" */),
  "component---src-pages-cloud-managed-cloud-server-js": () => import("./../../../src/pages/cloud/managed-cloud-server.js" /* webpackChunkName: "component---src-pages-cloud-managed-cloud-server-js" */),
  "component---src-pages-cloud-mssql-database-js": () => import("./../../../src/pages/cloud/mssql-database.js" /* webpackChunkName: "component---src-pages-cloud-mssql-database-js" */),
  "component---src-pages-cloud-mysql-database-js": () => import("./../../../src/pages/cloud/mysql-database.js" /* webpackChunkName: "component---src-pages-cloud-mysql-database-js" */),
  "component---src-pages-cloud-unmanaged-cloud-server-js": () => import("./../../../src/pages/cloud/unmanaged-cloud-server.js" /* webpackChunkName: "component---src-pages-cloud-unmanaged-cloud-server-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-agreements-js": () => import("./../../../src/pages/legal/agreements.js" /* webpackChunkName: "component---src-pages-legal-agreements-js" */),
  "component---src-pages-legal-cookie-policy-js": () => import("./../../../src/pages/legal/cookie-policy.js" /* webpackChunkName: "component---src-pages-legal-cookie-policy-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-terms-of-service-agreement-js": () => import("./../../../src/pages/legal/terms-of-service-agreement.js" /* webpackChunkName: "component---src-pages-legal-terms-of-service-agreement-js" */)
}

