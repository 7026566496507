import "./src/scss/motherepos.scss"
import "bootstrap/dist/js/bootstrap.min"
import React from "react"

import { ThemeContext } from "./src/context/currency-context"
export const wrapRootElement = ({ element }) => (
  <ThemeContext>{element}</ThemeContext>
)
// export const onClientEntry = () => {

// }

// export const onServiceWorkerUpdateReady = () => {
//   const answer = window.confirm(
//       `This Website has been updated. ` +
//       `Reload to display the latest version?`
//   )

//   if (answer === true) {
//       window.location.reload()
//   }
// }
